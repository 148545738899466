/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-27",
    versionChannel: "nightly",
    buildDate: "2023-12-27T00:14:54.557Z",
    commitHash: "28a3c7178a805ddb7027d30738b0e58acf94147e",
};
